@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PoppinsLight;
  src: url('../assets/fonts/Poppins-Light.ttf');
}
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: PoppinsMedium;
  src: url('../assets/fonts/Poppins-Medium.ttf');
}
:root {
  --PoppinsMedium: PoppinsMedium;
}
html {
  overflow-x: hidden;
}

.scroll-y-auto {
  overflow-y: auto;
  text-shadow: 0 0 black;
  transition: color 0.3s ease;
  color: rgba(255, 255, 255, 0);

  &::-webkit-scrollbar {
    height: 16px;
    width: 18px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 5px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    height: 16px;
    width: 16px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 5px solid transparent;
  }

  & {
    color: #8c8c8c;
  }
}
